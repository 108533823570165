import { template as template_5143673f80ef49b3b4fc5374e459d114 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5143673f80ef49b3b4fc5374e459d114(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
